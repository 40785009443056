import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/ios-7-design-guidelines",
  "date": "2013-08-03",
  "title": "",
  "author": "admin",
  "tags": ["development", "design", "ios"],
  "featuredImage": "feature.jpg",
  "excerpt": "With the release of iOS 7 design guidelines, app designers and developers will have to adjust the language to match the new \"flat\" design of iOS. In addition to the grid system, the dimensions of icons and commonly used elements, typography and iconography has been updated by Apple in many ways."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Here are the changes to the way you present your content.`}</p>
    <p><strong parentName="p">{`Deference`}</strong>{` - UI should not compete with content. It should help the user to understand the content.
`}<strong parentName="p">{`Clarity`}</strong>{` -Legible text in anysize, precise and lucid icons, subtle adornments, and functionality focused design.
`}<strong parentName="p">{`Depth`}</strong>{` - Visual layers and realistic motion guiding user understanding. Whether you are creating a new app orredesigning an existing one, follow how iOS 7 design guidelinesapproached the redesign of the built-in apps:`}</p>
    <ul>
      <li parentName="ul">{`First, strip away the UI to expose the app’s core functionality and reaffirm its relevance.`}</li>
      <li parentName="ul">{`Next, use the themes of iOS 7 to inform the design of the UI and the user experience.Restore details and embellishments with care and never gratuitously.`}</li>
      <li parentName="ul">{`Throughout, be prepared to defy precedent, question assumptions, and let a focus on content and functionality motivate every design decision.`}</li>
    </ul>
    <h2>{`Display changes`}</h2>
    <h3>{`1. The Grid System`}</h3>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/65dcf483-ff5c-4e80-a20d-0e245cded82f.jpg",
        "alt": "iOS 7 Grid System"
      }}></img></p>
    <h3>{`2. Tables /Lists`}</h3>
    <p>{`Tables, no longer have a container and uses the full width of the display now. Only the table headers are different from the context with different color highlighting the heading. Table items have a padding of 15pt either side, separated by a 1px line, margin 15pt to the left side and connect directly with the right side of the screen.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/f1909f6b-da09-4e82-b286-48d4a73ac021.jpg",
        "alt": "Tables"
      }}></img></p>
    <h3>{`3. Layers`}</h3>
    <p>{`The new translucent UI layout consists of several different layers in one screen. allowing you to align navigation and tab bar views with a custom view hierarchy to create a new interface. .`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/layers.jpg",
        "alt": "Layers"
      }}></img></p>
    <h3>{`4. Navigation, Status Bars Menus`}</h3>
    <p>{`Navigation bar includes a title, basic navigation and action buttons. The height of the navigation bar is shrunk to 32pt in landscape orientationto show more content below.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/navbar.png",
        "alt": "Layers"
      }}></img></p>
    <h3>{`5. Icons`}</h3>
    <p>{`Icons have gone borderless. They are resizable for different screen sizes for example landscape vs portrait. iPad vs iPhone.`}</p>
    <h2>{`Take advantage of the whole screen in your design`}</h2>
    <p>{`With the new transparent status bar, and the removal of insets and visual frames revealing more room, nowyou can extend the content to the edges of the screen. On screen keyboard, which hides part of the screen has been removed, and the hierarchical elements displayed in an translucentmanner, conveying content awareness.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/design_refined.jpg",
        "alt": "Layers"
      }}></img></p>
    <h2>{`Realism and visual indicators or Skeuomorphism is discouraged`}</h2>
    <p>{`Apple discourages the use of bevelled elements, gradients, and drop shadows. Skeuomorphism leads to "heavier UI elements that can overpower or compete with the content". Apple encourages to focus on the content with the UI playing a supporting role.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/design_see_screen_gamecenter.jpg",
        "alt": "Layers"
      }}></img></p>
    <h2>{`Depth to communicate hierarchy`}</h2>
    <p>{`Distinct and functional layers help create depth and establish hierarchy and order. The use of translucency provides a sense of context and place. And new approaches to animation and motion make even the simplest tasks more engaging.`}</p>
    <p>{`Of course, Apple would like designers to adopt the same approach to authoring apps, making use of transparency and transitions to help communicate hierarchical position.It's a big leap in the way we think about app design, but it's probably won't be long before users of iOS7 start to expect it.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/hierarchy.png",
        "alt": "iOS 7 Hierarchy"
      }}></img></p>
    <h2>{`More white space`}</h2>
    <p>{`More white space helps to reduce the clutter and improve the clarity by exposing content and functionality. Apple iOS 7 design guidelines emphasize on creating apps with simplicity and plenty of negative space.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/design_see_screen_contacts.jpg",
        "alt": "iOS 7 More White Space"
      }}></img></p>
    <h2>{`Meaningful Colors for the UI`}</h2>
    <p>{`A theme of system colors, that work well with dark and light backgrounds, provide a meaningful visual cue for the built-in apps like yellow in Notes.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/design_see_screen_notes.jpg",
        "alt": "Layers"
      }}></img></p>
    <h2>{`Dynamic system fonts`}</h2>
    <p>{`iOS 7 dynamic system fonts adjust letter spacing, font-weight and line height, enhancing readability at any size. For system or custom fonts, choose Dynamic Type so the app will respond to any text size. The updated Weather app for iOS 7 has the all-new dynamic type system with a full-screen view.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/weather.jpg",
        "alt": "iOS 7 Dynamic System Fonts"
      }}></img></p>
    <h2>{`Borderless Buttons`}</h2>
    <p>{`In iOS 7, buttons have been simplified to look simple with one color, borderless and explaining context to indicate interactivity. All bar buttons are borderless.And when it makes sense, a content-area button can display a thin border or tinted background that makes it distinctive. In the Messages app, the navigation bar, contact and send buttons have been replaced with text labels.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2013-10-03-ios-7-design-guidelines/design_simple_screen_mail2.jpg",
        "alt": "Simple screen"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      